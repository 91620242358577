import React from 'react';
import Helmet from "react-helmet";


export default function SEO(props, url) {
    
  let app = (process.env.GATSBY_APP_ENV || "LWHT"),
      baseUrl

  switch(true) {
      case app === "LWHT":
          baseUrl = "https://www.lodgeswithhottubs.com"
        break;
        case app === "LUXH":
          baseUrl = "https://www.luxury-hotels.co.uk"
        break;
      case app === "CH":
          baseUrl = "https://www.cottageholidays.com"
        break;
      case app === "CS":
        baseUrl = "https://www.coastal-stays.co.uk"
      break;
      case app === "CC":
        baseUrl = "https://cottages-cornwall.co.uk"
      break;
      case app === "HL":
        baseUrl = "https://hotels-london.co.uk"
      break;
      case app === "VH":
          baseUrl = "https://www.villaholidays.com"
        break;
      case app === "CW":
        baseUrl = "https://www.cottageweekend.co.uk"
      break;
      case app === "DFH":
        baseUrl = "https://www.dogfriendlyhotels.co.uk"
      break;
      case app === "FVR":
        baseUrl = "https://www.floridavacationrentals.co"
      break;
      case app === "LH":
        baseUrl = "https://www.lodgeholidays.com"
      break;
      case app === "LC":
        baseUrl = "https://www.luxurycottages.co.uk"
      break;
      case app === "RC":
        baseUrl = "https://www.remotecottages.co.uk"
      break;
      case app === "ROMC":
        baseUrl = "https://www.romanticcottages.co.uk"
      break;
      case app === "UH":
        baseUrl = "https://www.unique-holidays.co.uk"
        break;
      case app === "UB":
        baseUrl = "https://ukbreaks.co.uk"
      break;
    }

    
    let breadcrumbsArr = [];
    let path;
    let listArr;

    props.path.charAt(0) !== '/' ? path = '/' + props.path : path = props.path;
      
    listArr = path.split('/')
  
    if (listArr.length > 2 ){
   
      listArr.forEach( (element, index) => {
        let obj = {};
        if (element) {
          obj["@type"] = "ListItem";
          obj["position"] = index;
          obj["name"] = element;
          obj["item"] =  baseUrl + "/" + element + "/";
          url = url + element + "/";
          breadcrumbsArr.push(obj);
        }
      });
    } else {
      let obj = {};
      obj["@type"] = "ListItem";
      obj["position"] = 1;
      obj["name"] = 'home';
      obj["item"] =  baseUrl;
      breadcrumbsArr.push(obj);
    }

    return (
        <>
            <Helmet
                title={props.title}
                link={[
                  {"rel": "icon", 
                   "type": "image/png", 
                   "href": props.favicon.asset.fixed.src
                  },
                  {
                    "rel": "canonical",
                    "href": baseUrl + path
                 }
                ]}
                meta={[
                    {
                      name: "description",
                      content: props.description
                    },
                    {
                      name: "viewport",
                      content: "width=device-width, minimum-scale=1, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
                    },
                    {
                      name: "robots",
                      content: props.robots
                    },
                    {
                      property: "og:title",
                      content: props.title
                    },
                    {
                      property: "og:description",
                      content: props.description
                    },
                  ]
                    .concat(
                      props.keywords && props.keywords.length > 0 ? {
                            name: "keywords",
                            content: props.keywords.join(", ")
                          }
                        : []
                    )
                    .concat()}>
                {props.trustPilot && <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async/>} 
                
                <script type="application/ld+json">{`
                  {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": ${JSON.stringify(breadcrumbsArr)}
                  }
                `}</script>
            </Helmet>
        </>
    )
}